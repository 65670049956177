import { Box, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MemoizedButton } from "../../../SDK/button";
import { primary } from "../../../constants/theme";
import { newVehicles, vehicles } from "../../../constants/vehicleData";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";
import VehicleDetails from "../cards/vehicleDetails";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  padding: "20px",
  paddingTop: "8%",
  //background: primary?.lightBlue,
  height: "100vh",

  ".head-text": {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const VehicleDetailsInfo = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);
  const vehicleInfo = [...vehicles, ...newVehicles];
  const [data, setData] = useState();

  useEffect(() => {
    const dataInfo = vehicleInfo?.filter((item) => {
      return (
        state?.userQlData?.selectedVehicleId.toString() ===
        item?.vehicleId?.toString()
      );
    })[0];
    setData(dataInfo);
  }, []);

  const onClickAction = () => {
    const payload = {
      ...state?.userQlData,
      stage: "test-drive",
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  const onClickActionOtherBrand = () => {
    const payload = {
      ...state?.userQlData,
      stage: "all-brand",
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  return (
    <CustomCSSBox>
      <Box sx={{ width: "100%", height: "100%" }}>
        {/* <Typography className="head-text">
          What is your Purpose / Business for vehicle ?
        </Typography> */}
        {/* <Grid container spacing={2} sx={{ padding: "20px" }}>
          {data?.map((res, index) => {
            return (
              <Grid
                key={index}
                item
                sx={{ width: "100%", height: "100%" }}
                xs={6}
              >
                <BrandTapCard data={res} onClickAction={()=>onClickAction(res)} />
              </Grid>
            );
          })}
        </Grid> */}

        <VehicleDetails data={data} onClickAction={() => onClickAction()} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            className="head-text"
            // onClick={onClickAction3wEv}
            style={{
              cursor: "pointer",
              color: primary?.secondary,
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            <MemoizedButton
              content={"Looking for other brands?"}
              sx={{ background: primary?.secondary }}
              onClick={onClickActionOtherBrand}
            />
          </Typography>
        </Box>
      </Box>
    </CustomCSSBox>
  );
};

export default VehicleDetailsInfo;
