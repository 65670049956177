import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useRouter } from "next/router";
import { useContext } from "react";
import { MemoizedButton } from "../../../SDK/button";
import { primary } from "../../../constants/theme";
import { newVehicles, vehicles } from "../../../constants/vehicleData";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";
import VehicleHorizontalCard from "../cards/vehicleHorizontalCard";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  margin: "10px",
  marginTop: "8%",

  ".head-text": {
    fontSize: "26px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "20px",
  },

  [theme.breakpoints.down("md")]: {},
}));

const data = [
  {
    name: "Milk Distributor",
    vehicleSold: "550",
    businessLines: "Sells Milk, Curd, Paneer",
    imgUrl: imagesWEBP.milkDistributors,
    value: "Milk Distributor",
    stage: "range",
  },
  {
    name: "Water Distributor",
    vehicleSold: "600",
    businessLines: "Sells Packaged Water",
    imgUrl: imagesWEBP.waterDistributors,
    value: "Water Distributor",
    stage: "range",
  },
  {
    name: "E-commerce",
    vehicleSold: "700",
    businessLines: "Online Retailer",
    imgUrl: imagesWEBP.ecommerce,
    value: "E-commerce",
    stage: "range",
  },
  {
    name: "Cylinder Distribution",
    vehicleSold: "450",
    businessLines: "Sells Gas Cylinders",
    imgUrl: imagesWEBP.cylinder,
    value: "Cylinder Distribution",
    stage: "range",
  },
  {
    name: "Vegetable and Fruits",
    vehicleSold: "800",
    businessLines: "Sells Fresh Produce",
    imgUrl: imagesWEBP.vegetableFruits,
    value: "Vegetable and Fruits",
    stage: "range",
  },
  {
    name: "Others",
    vehicleSold: "500",
    businessLines: "Sells Various Fish, FMCG Products",
    imgUrl: imagesWEBP.fishDistribution,
    value: "Others",
    stage: "range",
  },
];

const BrandSpecificVehicle = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);
  const navigate = useRouter();

  const vehicleInfo = [...vehicles, ...newVehicles];

  const arrInfo = [];

  const recommendedVehicle = vehicleInfo?.filter((item) => {
    return (
      state?.userQlData?.brandName.toLowerCase() === item?.brand?.toLowerCase()
    );
  });

  const onClickAction = (data) => {
    const payload = {
      ...state?.userQlData,
      selectedVehicleId: data?.vehicleId,
      vehicleSelected: data?.vehicle_name,
      stage: "vehicle-details",
      business: data?.value,
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  const onClickActionOtherBrand = () => {
    const payload = {
      ...state?.userQlData,
      stage: "all-brand",
    };

    updateUserQlData(payload);
  };

  return (
    <CustomCSSBox>
      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30%",
        }}
      >
        {" "}
        <LazyLoadImage
          src={imagesWEBP?.homePiaggio}
          style={{ width: "70%", alighItems: "center" }}
        />
      </Box> */}

      <Box sx={{ width: "100%" }}>
        <Typography className="head-text">
          <span style={{ color: primary?.main, textTransform: "capitalize" }}>
            {state?.userQlData?.brandName}
          </span>{" "}
          Vehicle for your Business
        </Typography>

        <Box>
          <TableContainer
            style={{
              //height: "660px",
              background: "#F5F5F5",
              padding: "10px",
              borderRadius: "16px",
              width: "100%",
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableBody>
                {recommendedVehicle.map((res, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 2 },
                      paddingTop: "10px",
                    }}
                  >
                    <VehicleHorizontalCard
                      data={res}
                      onClickAction={onClickAction}
                      headerInfo={"View Details"}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            className="head-text"
            // onClick={onClickAction3wEv}
            style={{
              cursor: "pointer",
              color: primary?.secondary,
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            <MemoizedButton
              content={"Explore Other Brands Now!"}
              sx={{ background: primary?.secondary }}
              onClick={onClickActionOtherBrand}
            />
          </Typography>
        </Box>
      </Box>
    </CustomCSSBox>
  );
};

export default BrandSpecificVehicle;
