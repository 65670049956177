import { Box, Typography, styled } from "@mui/material";
import { primary } from "../../../constants/theme";

const CustomBusinessCard = styled(Box)(({ theme }) => ({
  width: "100%",
  ".business-card-container": {
    background: "rgba(255, 255, 255, 1)",

    borderRadius: "8px",
    padding: "16px",

    boxShadow: `1px 1px 1px 1px ${primary?.greyText}`,
    display: "flex",
    justifyContent: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const info = {
  label: "Mahindra",
  value: "550",
  onClick: () => {},
  selection: true,
};

function SelectTapCard({ data, onClickAction }) {
  return (
    <CustomBusinessCard>
      <Box
        className={`business-card-container`}
        onClick={() => onClickAction(data)}
        style={{
          background: data?.selection ? primary?.main : primary?.lightPink,
        }}
      >
        <Typography
          style={{
            fontSize: "1em",
            fontWeight: "500",
            color: data?.selection ? primary?.white : primary?.main,
          }}
        >
          {data?.label}
        </Typography>
      </Box>
    </CustomBusinessCard>
  );
}
export default SelectTapCard;
