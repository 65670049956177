function SvgIcon4({ className }) {
  return (
    <div className={`${className} ${className}`}>
      <svg
        fill="none"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <defs>
          <clipPath id="clip8_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="16"
              width="8.02"
              fill="white"
              transform="matrix(1, 0, 0, -1, 3.9901000000000018, 16.5715)"
            />
          </clipPath>
        </defs>
        <g id="::before" xmlns="http://www.w3.org/2000/svg">
          <g id="Icon" clipPath="url(#clip8_59_3437)">
            <path
              id="Vector"
              d="M12.0089,4.7678v0.9108c0,0.0833 -0.0268,0.1517 -0.0803,0.2053c-0.0536,0.0536 -0.122,0.0804 -0.2054,0.0804h-1.5c-0.1369,0.8571 -0.5208,1.5535 -1.1518,2.0893c-0.6309,0.5357 -1.4523,0.8631 -2.4643,0.9821c0.9941,1.0595 2.3602,2.6548 4.0983,4.7857c0.0833,0.0953 0.0952,0.1964 0.0357,0.3036c-0.0476,0.1071 -0.134,0.1607 -0.259,0.1607h-1.741c-0.0953,0 -0.1697,-0.0357 -0.2232,-0.1071c-1.8215,-2.1846 -3.3036,-3.884 -4.4465,-5.0983c-0.0535,-0.0535 -0.0803,-0.119 -0.0803,-0.1964v-1.1339c0,-0.0774 0.0283,-0.1444 0.0848,-0.2009c0.0565,-0.0566 0.1235,-0.0848 0.2009,-0.0848h1c0.7857,0 1.4181,-0.128 1.8973,-0.384c0.4792,-0.2559 0.7842,-0.6279 0.9152,-1.116h-3.8125c-0.0833,0 -0.1518,-0.0268 -0.2054,-0.0804c-0.0535,-0.0536 -0.0803,-0.122 -0.0803,-0.2053v-0.9108c0,-0.0833 0.0268,-0.1517 0.0803,-0.2053c0.0536,-0.0536 0.1221,-0.0804 0.2054,-0.0804h3.6875c-0.3393,-0.6726 -1.1369,-1.0089 -2.3929,-1.0089h-1.2946c-0.0774,0 -0.1444,-0.0283 -0.2009,-0.0848c-0.0565,-0.0566 -0.0848,-0.1235 -0.0848,-0.2009v-1.1875c0,-0.0833 0.0268,-0.1518 0.0803,-0.2054c0.0536,-0.0535 0.1221,-0.0803 0.2054,-0.0803h7.4286c0.0833,0 0.1517,0.0268 0.2053,0.0803c0.0536,0.0536 0.0804,0.1221 0.0804,0.2054v0.9107c0,0.0833 -0.0268,0.1518 -0.0804,0.2054c-0.0536,0.0535 -0.122,0.0803 -0.2053,0.0803h-2.0804c0.2798,0.3631 0.4702,0.7917 0.5714,1.2857h1.5268c0.0834,0 0.1518,0.0268 0.2054,0.0804c0.0535,0.0536 0.0803,0.122 0.0803,0.2053z"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon4;
