import { Box, Grid, Typography, styled } from "@mui/material";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";
import SelectTapCard from "../cards/selectTapCard";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  //display: "flex",
  //justifyContent: "center",
  margin: "20px",
  // marginTop:"30%",
  // marginTop:"100px",

  ".head-text": {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const data = [
  {
    label: "3.5 - 4L",
    value: "3.5-4",
    stage: "duration",

    selection: false,
  },
  {
    label: "4L - 5L",
    value: "4-5",
    stage: "duration",
    selection: false,
  },

  {
    label: "5L - 10L",
    value: "5-10",
    stage: "duration",
    selection: false,
  },

  {
    label: "Above 10L",
    value: "10",
    stage: "duration",
    selection: false,
  },
];

const PriceRange = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);

  const onClickAction = (data) => {
    const payload = {
      ...state?.userQlData,
      priceRange: data?.value,
      stage: data?.stage,
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  return (
    <CustomCSSBox>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30%",
        }}
      >
        {" "}
        <LazyLoadImage
          src={imagesWEBP?.homePiaggio}
          style={{ width: "70%", alighItems: "center", minHeight: "170px" }}
          alt="home piaggio"
        />
      </Box>

      <Box sx={{ width: "100%", height: "100%" }}>
        <Typography className="head-text">
          What is your budget for loading ev?{" "}
        </Typography>
        <Grid container spacing={2} sx={{ padding: "20px" }}>
          {data?.map((res, index) => {
            return (
              <Grid
                key={index}
                item
                sx={{ width: "100%", height: "100%" }}
                xs={6}
              >
                <SelectTapCard data={res} onClickAction={onClickAction} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </CustomCSSBox>
  );
};

export default PriceRange;
