function SvgIcon1({ className }) {
  return (
    <div className={`${className} ${className}`}>
      <svg
        fill="none"
        viewBox="0 0 9.4469 10.5"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <defs>
          <clipPath id="clip0_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="10.5"
              width="9.45"
              fill="white"
              transform="translate(-0.0019999999999988916, 0)"
            />
          </clipPath>
          <clipPath id="clip1_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="10.5"
              width="9.45"
              fill="white"
              transform="translate(-0.0019999999999988916, 0)"
            />
          </clipPath>
          <clipPath id="clip2_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="10.5"
              width="9.446"
              fill="white"
              transform="translate(0, 0)"
            />
          </clipPath>
        </defs>
        <g
          id="iconDiesel.svg"
          clipPath="url(#clip0_59_3437)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="iconDiesel.svg fill" clipPath="url(#clip1_59_3437)">
            <g id="iconDiesel.svg_2" clipPath="url(#clip2_59_3437)">
              <g id="Group 6545">
                <path
                  id="Path 5548"
                  d="M0.5,0.5h6.369v9.5h-6.369z"
                  fillRule="evenodd"
                  stroke="#59646F"
                />
                <path
                  id="Path 5549"
                  d="M3.6579,4.5001c-0.039,-0.008 -1.386,1.575 -1.386,2.249c0,0.3673 0.1459,0.7196 0.4057,0.9793c0.2597,0.2597 0.612,0.4057 0.9793,0.4057c0.3674,0 0.7196,-0.146 0.9794,-0.4057c0.2597,-0.2597 0.4056,-0.612 0.4056,-0.9793c0,-0.674 -1.346,-2.238 -1.384,-2.249z"
                  fillRule="evenodd"
                  stroke="#59646F"
                />
                <path id="Path 5550" d="M2.7351,2.406h1.855" stroke="#59646F" />
                <path
                  id="Path 5551"
                  d="M8.3068,4.163v-0.493"
                  stroke="#59646F"
                />
                <path
                  id="Path 5552"
                  d="M7.0659,8.4401h1.881v-6.541"
                  stroke="#59646F"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon1;
