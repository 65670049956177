import { Box, Grid, Typography, styled } from "@mui/material";
import { useContext } from "react";
import { primary } from "../../../constants/theme";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";
import BusinessTapCard from "../cards/businessTapCard";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  margin: "20px",
  marginTop: "8%",

  ".head-text": {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const data = [
  {
    name: "Milk Distributor",
    vehicleSold: "550",
    businessLines: "Sells Milk, Curd, Paneer",
    imgUrl: imagesWEBP.milkDistributors,
    value: "Milk Distributor",
    stage: "range",
  },
  {
    name: "Water Distributor",
    vehicleSold: "600",
    businessLines: "Sells Packaged Water",
    imgUrl: imagesWEBP.waterDistributors,
    value: "Water Distributor",
    stage: "range",
  },
  {
    name: "E-commerce",
    vehicleSold: "700",
    businessLines: "Online Retailer",
    imgUrl: imagesWEBP.ecommerce,
    value: "E-commerce",
    stage: "range",
  },
  {
    name: "Cylinder Distribution",
    vehicleSold: "450",
    businessLines: "Sells Gas Cylinders",
    imgUrl: imagesWEBP.cylinder,
    value: "Cylinder Distribution",
    stage: "range",
  },
  {
    name: "Vegetable and Fruits",
    vehicleSold: "800",
    businessLines: "Sells Fresh Produce",
    imgUrl: imagesWEBP.vegetableFruits,
    value: "Vegetable and Fruits",
    stage: "range",
  },
  {
    name: "Others",
    vehicleSold: "500",
    businessLines: "Sells Various Fish, FMCG Products",
    imgUrl: imagesWEBP.fishDistribution,
    value: "Others",
    stage: "range",
  },
];

const BusinessNeeds = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);

  const onClickAction = (data) => {
    const payload = {
      ...state?.userQlData,
      stage: data?.stage,
      business: data?.value,
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  return (
    <CustomCSSBox>
      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30%",
        }}
      >
        {" "}
        <LazyLoadImage
          src={imagesWEBP?.homePiaggio}
          style={{ width: "70%", alighItems: "center" }}
        />
      </Box> */}

      <Box sx={{ width: "100%", height: "100%" }}>
        <Typography className="head-text">
          What is your purpose or business for buying this vehicle?
        </Typography>
        <Grid container spacing={2} sx={{ padding: "20px" }}>
          {data?.map((res, index) => {
            return (
              <Grid
                key={index}
                item
                sx={{ width: "100%", height: "100%" }}
                xs={6}
              >
                <BusinessTapCard data={res} onClickAction={onClickAction} />
              </Grid>
            );
          })}
        </Grid>

        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: primary?.white,
            padding: "8px",
            background: primary?.secondary,
            borderRadius: "8px",
          }}
        >
          Based on your input we will recommend you best in class 3 wheeler EV
        </Typography>
      </Box>
    </CustomCSSBox>
  );
};

export default BusinessNeeds;
