import { Box, LinearProgress, styled, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { Context } from "../../context/context";
import LeadInfoService from "../../services/leadInfoService";
import { sendAnalytics } from "../../utils/analyticsCommon";
import useTestDriveScheduler from "./useTestDriveScheduler";

const CustomDatePicker = styled(Box)(({ theme }) => ({
  ".main-box": {
    margin: "20px",
    marginTop: "50px",
  },
  ".head-text": {
    fontWeight: "700",
    fontSize: "20px",
  },
  ".sub-head-text": {
    fontWeight: "600",
    fontSize: "12px",
  },
  ".sub-text": {
    marginTop: "50px",
    fontWeight: "600",
    fontSize: "12px",
  },

  // ".radio-button": {
  //   position: "absolute",
  //   top: "5px",
  //   left: "5px",
  //   padding: "0px",
  //   color: "#B8B7B9",
  // },
  ".back-button-box": {
    position: "absolute",
    bottom: "50px",
    left: "50px",
    background: "#ffffff",
  },
  ".back-button": {
    display: "flex",
    justifyContent: "Center",
    alignItems: "center",
  },
  ".MuiPickersLayout-actionBar": {
    visibility: "hidden",
  },
  ".MuiLinearProgress-root": {
    background: "#D9D9D9 !important",
    borderRadius: "8px",
  },
  ".MuiLinearProgress-bar": {
    background: "#35BF68",
  },
  ".MuiTypography-overline": {
    visibility: "hidden",
  },
  ".MuiPickersDay-root": {
    // fontWeight: "600",
    fontSize: "18px",
  },
}));

const DatePicker = () => {
  const { form, loading } = useTestDriveScheduler();
  const navigate = useRouter();
  const state = useContext(Context);
  const { updateTestDriveDetails, updateUserQlData } = useContext(Context);

  return (
    <CustomDatePicker>
      <Box className="main-box">
        <Box>
          <Typography className="head-text">Test Drive</Typography>
          <Typography className="sub-head-text">BY TURNO</Typography>
        </Box>
        <Box>
          <LinearProgress
            variant="determinate"
            value={25}
            // sx={{ width: "100%" }}
          />
        </Box>

        <Box>
          <Typography className="sub-text"> Select Date</Typography>
        </Box>

        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              defaultValue={dayjs(form.values.date)}
              disablePast={true}
              className="calendar"
              onChange={(e) => {
                form?.setFieldValue("date", e?.$d);
                const obj = {
                  ...state?.testDriveDetails,
                  date: e?.$d,
                  vehicleId: state?.userQlData?.selectedVehicleId,
                };

                let payload = {
                  date: e?.$d,
                };
                sendAnalytics("v2_test_drive_select_date", payload, "en");

                const finalPayload = {
                  ...state?.userQlData,
                  ...obj,
                  stage: "time-picker",
                };

                updateUserQlData(finalPayload);

                updateTestDriveDetails(obj);
                LeadInfoService(finalPayload);
                // navigate.push("/test-drive/time");
              }}
            />
          </LocalizationProvider>
        </Box>
        {/* <Box
          className="back-button-box"
          onClick={() => {
            sendAnalytics("v2_test_drive_back", {}, "en");
            navigate.push("/test-drive/vehicles");
          }}
        >
          <Typography className="back-button">
            <ArrowBackIosIcon sx={{ fontSize: "20px", width: "100%" }} /> Back
          </Typography>
        </Box> */}
      </Box>
    </CustomDatePicker>
  );
};

export default DatePicker;
