import { Box, Grid, Typography, styled } from "@mui/material";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";
import SelectTapCard from "../cards/selectTapCard";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  //display: "flex",
  //justifyContent: "center",
  margin: "20px",
  // marginTop:"30%",
  // marginTop:"100px",

  ".head-text": {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const data = [
  {
    label: "within 7days",
    value: "7days",
    stage: "rec-vehicle",

    selection: false,
  },
  {
    label: "7-15 days",
    value: "7-15days",
    stage: "rec-vehicle",
    selection: false,
  },

  {
    label: "15-30 days",
    value: "15-30 days",
    stage: "rec-vehicle",
    selection: false,
  },

  {
    label: "30-60 days",
    value: "30-60days",
    stage: "rec-vehicle",
    selection: false,
  },
];

const PurchaseDuration = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);

  const onClickAction = (data) => {
    const payload = {
      ...state?.userQlData,
      purchaseDuration: data?.value,
      stage: data?.stage,
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  return (
    <CustomCSSBox>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30%",
        }}
      >
        {" "}
        <LazyLoadImage
          src={imagesWEBP?.homePiaggio}
          style={{ width: "70%", alighItems: "center", minHeight: "170px" }}
          alt="home piaggio"
        />
      </Box>

      <Box sx={{ width: "100%", height: "100%" }}>
        <Typography className="head-text">
          When are you planning to buy?
        </Typography>
        <Grid container spacing={2} sx={{ padding: "20px" }}>
          {data?.map((res, index) => {
            return (
              <Grid
                key={index}
                item
                sx={{ width: "100%", height: "100%" }}
                xs={6}
              >
                <SelectTapCard data={res} onClickAction={onClickAction} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </CustomCSSBox>
  );
};

export default PurchaseDuration;
