import { Box, Typography, styled } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { primary } from "../../../constants/theme";

const CustomBusinessCard = styled(Box)(({ theme }) => ({
  ".business-card-container": {
    background: "rgba(255, 255, 255, 1)",
    //border: `1px solid ${primary?.greyText}`,
    width: "120px",
    height: "150px",
    borderRadius: "8px",
    padding: "8px",
    background: primary?.lightBlue,
    boxShadow: `1px 1px 1px 1px ${primary?.greyText}`,
  },

  ".image-container": {
    boxSizing: "border-box",
    display: "block",
    width: "67px",
    maxWidth: "initial",
    height: "100%",
    border: "none",
    objectFit: "cover",
  },

  ".img-content-96a56c69": {
    content:
      'url("https://assets.turnoclub.com/developer/offer-turno-images/S3/v2/HomePage/etrio.webp")',
  },

  ".business-info": {
    marginTop: "3px",
  },

  ".business-label": {
    padding: "0",
    margin: "0",
    fontSize: "12px",
    fontWeight: "600",
  },

  ".business-sold-count": {
    padding: "0",
    margin: "0",
    marginTop: "4px",
    fontSize: "10px",
  },

  ".sold-count-label": {
    fontSize: "10px",
    textAlign: "left",
    fontWeight: "600",
    color: primary?.main,
  },

  ".business-lines-label": {
    padding: "0",
    margin: "0",
    //marginTop: "8px",
    fontSize: "10px",
  },

  [theme.breakpoints.down("md")]: {},
}));

function BusinessTapCard({ data, onClickAction }) {
  return (
    <CustomBusinessCard>
      <Box
        className={`business-card-container`}
        onClick={() => onClickAction(data)}
      >
        <Box className={`padded-container`}>
          <LazyLoadImage className={`image-container`} src={data?.imgUrl} loading="lazy" alt={data?.name}/>
        </Box>

        <Box className={`business-info`}>
          <Typography className={`business-label`}>{data?.name}</Typography>
          <Typography className={`business-sold-count`}>
            <span className={`sold-count-label`}>
              {" "}
              {data?.vehicleSold}+ Vehicles Sold
            </span>
          </Typography>
          <Typography className={`business-lines-label`}>
            {data?.businessLines}
          </Typography>
        </Box>
      </Box>
    </CustomBusinessCard>
  );
}
export default BusinessTapCard;
