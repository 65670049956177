import { Box, Typography, styled } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { primary } from "../../../constants/theme";

const CustomBrandCard = styled(Box)(({ theme }) => ({
  ".business-card-container": {
    background: "rgba(255, 255, 255, 1)",
    //border: `1px solid ${primary?.greyText}`,
    maxWidth: "100px",
    borderRadius: "8px",
    padding: "8px",
    //background:primary?.lightBlue,
    boxShadow: `1px 1px 1px 1px ${primary?.greyText}`,
  },

  ".image-container": {
    boxSizing: "border-box",
    display: "block",
    //width: "100%",

    maxWidth: "64px",
    border: "none",
    objectFit: "content",
    textAlign: "center",
    margin: "auto",
    marginTop: "10px",
    marginBottom: "10px",
  },

  ".business-info": {
    marginTop: "3px",
  },

  ".business-label": {
    padding: "0",
    margin: "0",
    fontSize: "12px",
  },

  ".business-sold-count": {
    padding: "0",
    margin: "0",
    marginTop: "4px",
    fontSize: "10px",
  },

  ".sold-count-label": {
    fontSize: "10px",
    textAlign: "left",
    fontWeight: "600",
    // color:primary?.main
  },

  ".business-lines-label": {
    padding: "0",
    margin: "0",
    marginTop: "8px",
    fontSize: "10px",
  },

  [theme.breakpoints.down("md")]: {},
}));

// const data = {
//   name: "Mahindra",
//   vehicleSold: "550",
//   businessLines: "Best Seller",
//   imgUrl:
//     "https://assets.turnoclub.com/developer/offer-turno-images/S3/v2/Vehicles/mahidra_logo.webp",
// };

function BrandTapCard({ data, onClickAction }) {
  return (
    <CustomBrandCard>
      <Box className={`business-card-container`} onClick={onClickAction}>
        <Box className={`padded-container`}>
          <LazyLoadImage
            className={`image-container`}
            src={data?.vehicle_logo}
            alt="vehicle logo"
          />
        </Box>

        <Box className={`business-info`}>
          {/* <Typography className={`business-label`}>{data?.name}</Typography> */}
          <Typography className={`business-sold-count`}>
            <span className={`sold-count-label`}>
              {" "}
              {data?.vehicleSold}+ Vehicles Sold
            </span>
          </Typography>
          <Typography className={`business-lines-label`}>
            {data?.businessLines}
          </Typography>
        </Box>
      </Box>
    </CustomBrandCard>
  );
}
export default BrandTapCard;
