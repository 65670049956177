import { Box, Grid, Typography, styled } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MemoizedButton } from "../../../SDK/button";
import { primary } from "../../../constants/theme";

const CustomVehicleDetailsCard = styled(Box)(({ theme }) => ({
  ".business-card-container": {
    padding: "10px 7px",
    background: "rgba(255, 255, 255, 1)",
    border: "2px solid rgba(0, 0, 0, 1)",
  },

  ".padded-container": {
    paddingRight: "7px",
    paddingLeft: "7px",
  },

  ".image-container": {
    boxSizing: "border-box",
    display: "block",
    width: "100%",
    maxWidth: "initial",

    border: "none",
    objectFit: "cover",
  },

  ".business-info": {
    paddingRight: "24px",
    marginTop: "3px",
  },

  ".business-label": {
    padding: "0",
    paddingRight: "7px",
    paddingLeft: "7px",
    margin: "0",
    font: "400 10px Inter",
    color: "rgba(0, 0, 0, 1)",
  },

  ".business-sold-count": {
    padding: "0",
    paddingRight: "6px",
    paddingLeft: "6px",
    margin: "0",
    marginTop: "4px",
    font: "400 10px Inter",
    color: "rgba(0, 0, 0, 1)",
  },

  ".sold-count-label": {
    font: "600 10px Inter",
    textAlign: "left",
  },

  ".business-lines-label": {
    padding: "0",
    margin: "0",
    marginTop: "12px",
    font: "400 8px Inter",
    color: "rgba(0, 0, 0, 1)",
  },

  ".load-container": {
    boxSizing: "border-box",
    display: "flex",

    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    padding: "12px",

    background: primary?.lightGrey,
    borderRadius: "4px",
    width: "100%",
    alignItems: "center",
  },

  ".top-label": {
    fontSize: "14px",
    fontWeight: "500",
    color: `${primary?.greyText}`,
    textAlign: "center",
  },

  ".top-value": {
    marginTop: "7px",
    fontSize: "16px",
    fontWeight: "600",

    textAlign: "center",
  },

  ".vehicle-specs": {
    //margin:"20px",
    width: "100%",
    margin: "auto",
    marginTop: "10px",
    marginBottom: "10px",
  },

  [theme.breakpoints.down("md")]: {},
}));

// const data = {
//   vehicleImage:
//     "https://assets.turnoclub.com/developer/offer-turno-images/S3/v2/Vehicles/mahindra-zor/pickup/mobile/Mahindratreopickup0.webp",
//   logoImage:
//     "https://assets.turnoclub.com/developer/offer-turno-images/S3/v2/Vehicles/mahidra_logo.webp",
//   load: "500 kg",
//   topSpeed: "50 km/h",
//   range: "150 kms",
//   ctaText: "Book Test Drive Today",
// };

function VehicleDetails({ data, onClickAction }) {
  return (
    <CustomVehicleDetailsCard>
      <Box className={`vehicle-details`}>
        <Box className={`vehicle-details1`}>
          <LazyLoadImage
            className={`image-container`}
            src={data?.latestModel}
            alt="latest model"
          />
        </Box>

        <Box className={`vehicle-details1`}>
          <LazyLoadImage
            className={`image-container`}
            style={{ width: "100px", marginTop: "20px" }}
            src={data?.logoImage}
            alt="logo image"
          />
        </Box>
        <Box>
          {" "}
          <Typography className={`top-label`} style={{ textAlign: "left" }}>
            {" "}
            <span
              style={{
                fontWeight: "600",
                color: primary?.main,
                fontSize: "18px",
              }}
            >
              {data?.brand}
            </span>{" "}
            {data?.vehicle_name}
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Grid item xs={4}>
            <Box className={`load-container`}>
              <Typography className={`top-label`}>TOP RANGE</Typography>
              <Typography className={`top-value`}>{data?.range}</Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box className={`load-container`}>
              <Typography className={`top-label`}>TOP LOAD</Typography>
              <Typography className={`top-value`}>{data?.load}</Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box className={`load-container`}>
              <Typography className={`top-label`}>TOP SPEED</Typography>
              <Typography className={`top-value`}>{data?.topSpeed}</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          //sx={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Grid item xs={6}>
            <Box className={`load-container`}>
              <Typography className={`top-label`}>STARTING Price</Typography>
              <Typography className={`top-value`}>
                ₹ {data?.priceInLakhs} L
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className={`load-container`}>
              <Typography className={`top-label`}>STARTING EMI</Typography>
              <Typography className={`top-value`}>
                ₹ {data?.emiPrice} / month
              </Typography>
            </Box>
          </Grid>

          {/* <Grid item xs={4}>
            <Box className={`load-container`}>
              <Typography className={`top-label`}>TOP SPEED</Typography>
              <Typography className={`top-value`}>{data?.topSpeed}</Typography>
            </Box>
          </Grid> */}
        </Grid>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <MemoizedButton
            sx={{ width: "100%", borderRadius: "8px" }}
            content={"Book Test Drive Today"}
            onClick={onClickAction}
          />
        </Box>
      </Box>
    </CustomVehicleDetailsCard>
  );
}
export default VehicleDetails;
