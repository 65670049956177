import { Box, Grid, Typography, styled } from "@mui/material";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MemoizedButton } from "../../../SDK/button";
import { primary } from "../../../constants/theme";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";
import SelectTapCard from "../cards/selectTapCard";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  //display: "flex",
  //justifyContent: "center",
  margin: "20px",
  // marginTop:"30%",
  // marginTop:"100px",

  ".head-text": {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const data = [
  {
    label: "2 wheeler",
    value: "2 wheeler",
    stage: "other-requirement",
    selection: false,
  },
  {
    label: "4 wheeler",
    value: "4 wheeler",
    stage: "other-requirement",
    selection: false,
  },
  {
    label: "Cargo Petrol",
    value: "Cargo Petrol",
    stage: "other-requirement",
    selection: false,
  },
  {
    label: "Cargo Diesel",
    value: "Cargo Diesel",
    stage: "other-requirement",
    selection: false,
  },
  {
    label: "Cargo CNG",
    value: "Cargo CNG",
    stage: "other-requirement",
    selection: false,
  },
  {
    label: "Passenger EV",
    value: "Passenger EV",
    stage: "other-requirement",
    selection: false,
  },
];

const DiffrentVehicle = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);

  const onClickAction = (data) => {
    const payload = {
      ...state?.userQlData,
      mobile: "+91" + localStorage.getItem("phoneInfo") ?? "",
      otherBusiness: data?.value,
      stage: data?.stage,
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  const onClickAction3wEv = (data) => {
    const payload = {
      ...state?.userQlData,
      isThreeWheelerReq: true,
      mobile: "+91" + localStorage.getItem("phoneInfo") ?? "",

      stage: "business",
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  return (
    <CustomCSSBox>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        {" "}
        <LazyLoadImage
          src={imagesWEBP?.homePiaggio}
          style={{ width: "70%", alighItems: "center", minHeight: "170px" }}
          alt="home piaggio"
        />
      </Box>

      <Box sx={{ width: "100%", height: "100%" }}>
        <Typography className="head-text">
          Are you looking for other options?
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "10px", marginBottom: "20px" }}
        >
          {data?.map((res, index) => {
            return (
              <Grid
                key={index}
                item
                sx={{ width: "100%", height: "100%" }}
                xs={6}
              >
                <SelectTapCard data={res} onClickAction={onClickAction} />
              </Grid>
            );
          })}
        </Grid>

        <Typography
          className="head-text"
          onClick={onClickAction3wEv}
          style={{
            cursor: "pointer",
            color: primary?.secondary,
            fontWeight: "600",
            fontSize: "18px",
          }}
        >
          <MemoizedButton
            content={"OR Continue with 3-wheeler EV"}
            onClick={onClickAction3wEv}
          />
        </Typography>
      </Box>
    </CustomCSSBox>
  );
};

export default DiffrentVehicle;
