import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Drawer } from "@mui/material";
import { useContext } from "react";
import { Context } from "../../../context/context";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import DatePicker from "../../TestDriveScheduler/datePicker";
import Location from "../../TestDriveScheduler/location";
import TimeSelector from "../../TestDriveScheduler/timeSelector";
import BrandAvailable from "../UserDataComponents/BrandAvailable";
import BrandSpecificVehicle from "../UserDataComponents/BrandSpecificVehicles";
import BusinessNeeds from "../UserDataComponents/BusinessNeeds";
import DiffrentVehicle from "../UserDataComponents/DifferentBusiness";
import LoadCapacity from "../UserDataComponents/LoadCapacity";
import PriceRange from "../UserDataComponents/PriceRange";
import PurchaseDuration from "../UserDataComponents/PurchaseDuration";
import RangeSelection from "../UserDataComponents/RangeSelection";
import RecommendByChoice from "../UserDataComponents/RecommendByChoice";
import RecommendedVehicle from "../UserDataComponents/RecommendedVehicle";
import ThreeWheelerEv from "../UserDataComponents/ThreeWheelerEV";
import VehicleDetailsInfo from "../UserDataComponents/VehicleDetailsInfo";
import WhySwitchtoEv from "../UserDataComponents/WhySwitchToEV";

const QlDrawerComponent = () => {
  const state = useContext(Context);
  const { hideReviewStoryDrawer } = useContext(Context);
  const open = state?.showReviewStoryDrawerValue;

  const changeComponents = (userStage) => {
    {
      switch (userStage) {
        case "business":
          return (
            <Box sx={{ transition: "height 1s linear" }}>
              <BusinessNeeds />
            </Box>
          );
        case "range":
          return <RangeSelection />;

        case "duration":
          return (
            <Box sx={{ transition: "height 1s linear" }}>
              <PurchaseDuration />
            </Box>
          );

        case "price-range":
          return (
            <Box sx={{ transition: "height 1s linear" }}>
              <PriceRange />
            </Box>
          );

        case "load-capacity":
          return (
            <Box sx={{ transition: "height 1s linear" }}>
              <LoadCapacity />
            </Box>
          );

        case "rec-vehicle":
          return (
            <Box>
              <RecommendedVehicle />
            </Box>
          );

        case "no-ev-flow":
          return (
            <Box>
              <DiffrentVehicle />
            </Box>
          );

        case "other-requirement":
          return (
            <Box>
              <WhySwitchtoEv />
            </Box>
          );

        case "recommend-options":
          return (
            <Box>
              <RecommendByChoice />
            </Box>
          );

        case "test-drive":
          return (
            <Box>
              {" "}
              <DatePicker />
            </Box>
          );

        case "time-picker":
          return (
            <Box>
              <TimeSelector />
            </Box>
          );

        case "location":
          return (
            <Box>
              <Location />
            </Box>
          );

        case "all-brand":
          return (
            <Box>
              <BrandAvailable />
            </Box>
          );

        case "selected-brand":
          return (
            <Box>
              <BrandSpecificVehicle />
            </Box>
          );

        case "vehicle-details":
          return (
            <Box>
              <VehicleDetailsInfo />
            </Box>
          );

        default:
          return <ThreeWheelerEv />;
      }
    }
  };

  return (
    <Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          hideReviewStoryDrawer();
        }}
        onOpen={
          () => {
            sendAnalytics(
              "ql_flow_opened",
              { stage: state?.userQlData?.stage },
              "en"
            );
          }
        }
        transitionDuration={1000}
        // style={{

        // }}
        sx={{
          "& .MuiDrawer-paper": {
            // position: "relative",
            overflowY: "visible",
            height: "100%",
            borderRadius: "0px 0px 0px 0px",
          },
        }}
        PaperProps={{
          sx: { display: { xs: "block", sm: "none" } },
        }}
      >
        <Box
          onClick={() => {
            hideReviewStoryDrawer();
            sendAnalytics(
              "ql_flow_closed",
              { stage: state?.userQlData?.stage },
              "en"
            );
          }}
          style={{
            fontSize: "20px",
            color: "grey",
            position: "absolute",
            right: "46%",
            bottom: "4px",
            zIndex: "999",
          }}
        >
          <CancelIcon fontSize="large" />
        </Box>
        <Box sx={{ height: "100%" }}>
          {changeComponents(state?.userQlData?.stage)}
        </Box>
      </Drawer>
    </Box>
  );
};

export default QlDrawerComponent;
