import { Box, Typography, styled } from "@mui/material";
import SvgIcon1 from "./icons/SvgIcon1";
import SvgIcon2 from "./icons/SvgIcon2";
import SvgIcon3 from "./icons/SvgIcon3";
import SvgIcon4 from "./icons/SvgIcon4";
//import './style.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MemoizedButton } from "../../../SDK/button";
import { primary } from "../../../constants/theme";

const CustomVehicleThumbCard = styled(Box)(({ theme }) => ({
  marginTop: "10px",

  ".mahindra-card": {
    display: "flex",
    flexDirection: "column",
    //alignItems: "stretch",
    justifyContent: "center",
    padding: "17px 12px 14px",
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "14px",
    width: "100%",
    boxShadow: "0px 0px 4px 0px #00000040",
    overflow: "auto",
  },

  ".car-brand-label": {
    flex: "0 0 auto",
    padding: "0",
    paddingRight: "4px",
    paddingLeft: "4px",
    margin: "0",
    font: "600 11px Poppins",
    color: "rgba(152, 166, 179, 1)",
  },

  ".vehicle-details-container": {
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    // marginTop:"8px",
  },

  ".vehicle-details-container1": {
    flex: "0 0 auto",
    paddingTop: "13px",
    paddingBottom: "3px",
    marginRight: "20px",
  },

  ".xuv-title": {
    padding: "0",
    paddingRight: "4px",
    paddingLeft: "4px",
    margin: "0",
    font: "600 14px Poppins",
    color: "rgba(18, 34, 50, 1)",
  },

  ".vehicle-details": {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "8.5px",
  },

  ".flex-end-container": {
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "10px",
  },

  ".svg-container": {
    display: "flex",
    flex: "0 0 auto",
    width: "10px",
    height: "10px",
    marginRight: "4.25px",
  },

  ".vehicle-type-label": {
    flex: "0 0 auto",
    padding: "0",
    margin: "0",
    font: "400 11px Poppins",
    color: "rgba(89, 100, 111, 1)",
  },

  ".svg-container1": {
    display: "flex",
    flex: "0 0 auto",
    width: "12px",
    height: "12px",
  },

  ".flex-end-container1": {
    display: "flex",
    flex: "0 0 auto",
    //flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: "flex-end",
  },

  ".manual-text": {
    flex: "0 0 auto",
    padding: "0",
    margin: "0",
    marginRight: "14px",
    font: "400 11px Poppins",
    color: "rgba(89, 100, 111, 1)",
  },

  ".svg-container2": {
    display: "flex",
    flex: "0 0 auto",
    width: "14px",
    height: "14px",
  },

  ".fuel-price-container": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "16.5px",
  },

  ".svg-container3": {
    display: "flex",
    flex: "0 0 auto",
    width: "16px",
    height: "16px",
    color: "rgba(33, 37, 41, 1)",
  },

  ".count-label": {
    flex: "0 0 auto",
    padding: "0",
    margin: "0",
    marginLeft: "0px",
    font: "600 20px Poppins",
    color: "rgba(27, 28, 36, 1)",
  },

  ".fuel-info-text": {
    padding: "0",
    paddingRight: "4px",
    paddingLeft: "4px",
    margin: "0",
    marginTop: "12px",
    font: "400 11px Poppins",
    color: "rgba(89, 100, 111, 1)",
  },

  ".fuel-cost-excluded": {
    font: "500 11px Poppins",
    color: "rgba(18, 34, 50, 1)",
    textTransform: "lowercase",
  },

  ".flex-container-column": {
    display: "flex",
    justifyContent: "center",
    //flex: '0 0 auto',
    flexDirection: "column",
    width: "100%",
    // alignItems: 'flex-end',
    //justifyContent: 'flex-start',
  },

  ".image-button": {
    boxSizing: "border-box",
    display: "block",
    flex: "0 0 auto",
    alignSelf: "stretch",
    width: "92px",
    maxWidth: "initial",
    //height: '58px',
    border: "none",
  },

  ".gradient-button": {
    boxSizing: "border-box",
    display: "inline-flex",
    flex: "0 0 auto",
    // gap: '14px',
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "41px",
    marginTop: "14px",
    fontSize: "12px",

    cursor: "pointer",
    background: primary?.main,
    border: "none",
    borderRadius: "8px",
    marginLeft: "0px",
    padding: "10px",
  },

  ".svg-container4": {
    display: "flex",
    width: "10px",
    height: "10px",
  },

  [theme.breakpoints.down("md")]: {},
}));

function VehicleHorizontalCard({ data, onClickAction, headerInfo }) {
  return (
    <CustomVehicleThumbCard>
      <Box className={`mahindra-card`} onClick={() => onClickAction(data)}>
        <Typography className={`car-brand-label`}>{data?.brand}</Typography>
        <Box className={`vehicle-details-container`}>
          <Box className={`vehicle-details-container1`}>
            <Typography className={`xuv-title`}>
              {data?.vehicle_name}
            </Typography>
            <Box className={`vehicle-details`}>
              <Box className={`flex-end-container`}>
                <SvgIcon1 className="svg-container" />
                <Typography className={`vehicle-type-label`}>
                  {data?.load}
                </Typography>
              </Box>
              <SvgIcon2 className="svg-container1" />
              <Box className={`flex-end-container1`}>
                <Typography className={`manual-text`}>{data?.range}</Typography>
                <SvgIcon3 className="svg-container2" />
              </Box>
              <Typography className={`vehicle-type-label`}>
                {data?.topSpeed}
              </Typography>
            </Box>
            <Box className={`fuel-price-container`}>
              <SvgIcon4 className="svg-container3" />
              <Typography className={`count-label`}>
                {data?.emiPrice}{" "}
                <span className="vehicle-type-label"> / month</span>
              </Typography>
            </Box>
            <Typography className={`fuel-info-text`}>
              <span>{data?.warrantyBattery} battery warranty </span>
            </Typography>

            <Typography
              className={`fuel-info-text`}
              style={{ marginTop: "0px" }}
            >
              <span>
                {" "}
                Starting from{" "}
                <span style={{ fontWeight: "600" }}>
                  {data?.priceInLakhs} Lakhs
                </span>{" "}
              </span>
            </Typography>
          </Box>
          <Box className={`flex-container-column`}>
            <LazyLoadImage
              className={`image-button `}
              src={data?.latestModel}
              alt="latest model"
            />

            {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
            <MemoizedButton content={headerInfo} className="gradient-button" />
          </Box>
        </Box>
      </Box>
    </CustomVehicleThumbCard>
  );
}
export default VehicleHorizontalCard;
