function SvgIcon3({ className }) {
  return (
    <div className={`${className} ${className}`}>
      <svg
        fill="none"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <defs>
          <clipPath id="clip5_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="13.87"
              width="13.87"
              fill="white"
              transform="translate(-0.009700000000009368, 0.06650000000000489)"
            />
          </clipPath>
          <clipPath id="clip6_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="13.87"
              width="13.87"
              fill="white"
              transform="translate(-0.009700000000009368, 0.06650000000000489)"
            />
          </clipPath>
          <clipPath id="clip7_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="13.87"
              width="13.87"
              fill="white"
              transform="translate(-0.009700000000009368, 0.06650000000000489)"
            />
          </clipPath>
        </defs>
        <g
          id="iconSeat.svg"
          clipPath="url(#clip5_59_3437)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="iconSeat.svg fill" clipPath="url(#clip6_59_3437)">
            <g id="iconSeat.svg_2" clipPath="url(#clip7_59_3437)">
              <g id="Group 6550">
                <g id="Group 6549">
                  <g id="Group 6548">
                    <g id="Group 6547">
                      <g id="Group 6546">
                        <path
                          id="Ellipse 211"
                          d="M3.4233,3.8498c0.696,0 1.261,-0.5645 1.261,-1.261c0,-0.6964 -0.565,-1.261 -1.261,-1.261c-0.697,0 -1.261,0.5646 -1.261,1.261c0,0.6965 0.564,1.261 1.261,1.261z"
                          stroke="#59646F"
                          strokeWidth="1.00029"
                        />
                        <path
                          id="Ellipse 212"
                          d="M3.8103,12.5464c0.625,0 1.132,-0.5068 1.132,-1.132c0,-0.6252 -0.507,-1.132 -1.132,-1.132c-0.625,0 -1.132,0.5068 -1.132,1.132c0,0.6252 0.507,1.132 1.132,1.132z"
                          fill="#59646F"
                        />
                        <path
                          id="Path 5554"
                          d="M3.2463,9.1891l-0.559,-3.451h2.942l0.572,3.9952h4.682l0.8,2.9388h-5.847"
                          stroke="#59646F"
                          strokeWidth="1.00029"
                        />
                        <path
                          id="Path 5555"
                          d="M3.8083,5.5276l-0.351,-1.58"
                          stroke="#59646F"
                          strokeWidth="1.00029"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon3;
