import axios from "axios";

export const metaDatasService = async (stateId) => {
  return axios.get("/v1/metadata", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    withCredentials: false,
  });
};
