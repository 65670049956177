import { Box, Grid, LinearProgress, styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/context";
import LeadInfoService from "../../services/leadInfoService";
import { sendAnalytics } from "../../utils/analyticsCommon";
import useTestDriveScheduler from "./useTestDriveScheduler";

const CustomTimeSelector = styled(Box)(({ theme }) => ({
  ".main-box": {
    margin: "20px",
    marginTop: "50px",
  },
  ".head-text": {
    fontWeight: "700",
    fontSize: "20px",
  },
  ".sub-head-text": {
    fontWeight: "600",
    fontSize: "12px",
  },
  ".sub-text": {
    marginTop: "50px",
    fontWeight: "600",
    fontSize: "12px",
  },
  ".vehicle-box": {
    border: "1px solid #EFEFEF",
    position: "relative",
    width: "100%",
    margin: "2px",
    borderRadius: "4px",
  },
  ".vehicle-name": {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "700",
    padding: "10px",
  },
  ".back-button-box": {
    position: "absolute",
    bottom: "50px",
    left: "50px",
    background: "#ffffff",
  },
  ".back-button": {
    display: "flex",
    justifyContent: "Center",
    alignItems: "center",
  },
  ".MuiLinearProgress-root": {
    background: "#D9D9D9 !important",
    borderRadius: "8px",
  },
  ".MuiLinearProgress-bar": {
    background: "#35BF68",
  },
}));

const TimeSelector = () => {
  const { form, loading } = useTestDriveScheduler();
  const state = useContext(Context);
  const { updateTestDriveDetails, updateUserQlData } = useContext(Context);
  const navigate = useRouter();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(
    form?.values?.timeSlot
  );

  useEffect(() => {
    setSelectedTimeSlot(form?.values?.timeSlot);
  }, [form?.values?.timeSlot]);

  let timeSlotArray = [
    { id: "1", value: "9 PM - 11 PM", label: "9 PM - 11 PM " },
    { id: "1", value: "11 PM - 2 PM", label: "11 PM - 2 PM" },
    { id: "1", value: "2 PM - 6 PM", label: "2 PM - 6 PM" },
    { id: "1", value: "6 PM - 10 PM", label: "6 PM - 10 PM" },
  ];

  return (
    <CustomTimeSelector>
      <Box className="main-box">
        <Box>
          <Typography className="head-text">Test Drive</Typography>
          <Typography className="sub-head-text">BY TURNO</Typography>
        </Box>
        <Box>
          <LinearProgress variant="determinate" value={50} />
        </Box>

        <Box>
          <Typography className="sub-text"> Select Electric Vehicle</Typography>
        </Box>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={4}
          style={{ marginTop: "40px" }}
        >
          {timeSlotArray?.map((timeSlot, index) => {
            return (
              <Grid item xs={6} style={{ width: "50%" }} key={index}>
                <Box
                  className="vehicle-box"
                  onClick={() => {
                    form.setFieldValue("timeSlot", timeSlot?.value);
                    const obj = {
                      ...state?.testDriveDetails,
                      timeSlot: timeSlot?.value,
                      vehicleId: state?.userQlData?.selectedVehicleId,
                      stage: "location",
                    };

                    let payload = {
                      time_slot: timeSlot?.value,
                    };
                    sendAnalytics(
                      "v2_test_drive_select_time_slot",
                      payload,
                      "en"
                    );

                    updateTestDriveDetails(obj);

                    const finalPayload = { ...state?.userQlData, ...obj };

                    updateUserQlData(finalPayload);
                    LeadInfoService(finalPayload);
                    // navigate.push("/test-drive/location");
                  }}
                >
                  <Box
                    style={{
                      background:
                        form?.values?.timeSlot === timeSlot?.value
                          ? "#FEEBEF"
                          : "#ffffff",
                      padding: "20px",
                      border:
                        form?.values?.timeSlot === timeSlot?.value
                          ? "1px solid #E8345E"
                          : "none",
                    }}
                  >
                    <Typography style={{ textAlign: "center" }}>
                      {timeSlot?.label}
                    </Typography>
                    {/* <LazyLoadImage
                      src={vehicleData?.homeImage}
                      style={{ width: "100%", minHeight: "140px" }}
                    /> */}
                  </Box>

                  {/* <Box>
                    <Typography className="vehicle-name">
                      {vehicleData?.name}
                    </Typography>
                  </Box> */}
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {/* <Box
          className="back-button-box"
          onClick={() => {
            sendAnalytics("v2_test_drive_back", {}, "en");
            navigate.push("/test-drive/date");
          }}
        >
          <Typography className="back-button">
            <ArrowBackIosIcon sx={{ fontSize: "20px", width: "100%" }} /> Back
          </Typography>
        </Box> */}
      </Box>
    </CustomTimeSelector>
  );
};

export default TimeSelector;
