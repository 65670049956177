import HomeIcon from "@mui/icons-material/Home";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import { Box, Grid, LinearProgress, styled, Typography } from "@mui/material";
import axios from "axios";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/context";
import { MemoizedButton } from "../../SDK/button";
import { MemoizedNameField } from "../../SDK/input/nameInput";
import { MemoizedSelectDropDown } from "../../SDK/selectDropdown/selectDropdown";
import { checkError } from "../../SDK/utils/validationHelpers";
import { metaDatasService } from "../../services/metaDatas";
import { sendAnalytics } from "../../utils/analyticsCommon";
import useTestDriveScheduler from "./useTestDriveScheduler";
const CustomLocation = styled(Box)(({ theme }) => ({
  ".main-box": {
    margin: "20px",
    marginTop: "50px",
  },
  ".head-text": {
    fontWeight: "700",
    fontSize: "20px",
  },
  ".sub-head-text": {
    fontWeight: "600",
    fontSize: "12px",
  },
  ".sub-text": {
    marginTop: "50px",
    fontWeight: "600",
    fontSize: "12px",
  },
  ".location-box": {
    border: "1px solid #EFEFEF",
    position: "relative",
    width: "100%",
    margin: "2px",
    borderRadius: "4px",
    // paddingLeft: "50px",
  },
  ".vehicle-name": {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "700",
    padding: "10px",
  },

  ".back-button-box": {
    // position: "absolute",
    bottom: "50px",
    left: "50px",
    background: "#ffffff",
  },
  ".back-button": {
    display: "flex",
    justifyContent: "Center",
    alignItems: "center",
  },
  ".book-test-drive-button-box": {
    marginTop: "30px",
  },
  ".input-box": {
    width: "100%",
  },
  ".location-form-box": {
    marginTop: "10px",
  },
  ".MuiLinearProgress-root": {
    background: "#D9D9D9 !important",
    borderRadius: "8px",
  },
  ".MuiLinearProgress-bar": {
    background: "#35BF68",
  },
}));

const Location = () => {
  const { form, loading } = useTestDriveScheduler();
  const state = useContext(Context);
  const { updateTestDriveDetails, showSnackbar, updateUserQlData } =
    useContext(Context);
  const navigate = useRouter();
  const [selectedLocation, setSelectedLocation] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showCity, setShowCity] = useState(false);
  let locations = [
    {
      id: "1",
      value: "Store",
      label: "At Store",
      icon: <StorefrontRoundedIcon />,
    },
    { id: "1", value: "Home", label: "At Home", icon: <HomeIcon /> },
  ];

  useEffect(() => {
    metaDatasService()
      .then((res) => {
        setStates(res?.data?.payload?.states);
      })
      .catch((err) => {
        showSnackbar("Oops, Something went wrong Try Again!!", "error");
      });
    // eslint-disable-next-line
  }, []);

  const service = (query) => {
    return axios.get(`/v1/metadata?${query}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      withCredentials: false,
    });
  };

  return (
    <CustomLocation>
      <Box className="main-box">
        <Box>
          <Typography className="head-text">Test Drive</Typography>
          <Typography className="sub-head-text">BY TURNO</Typography>
        </Box>
        <Box>
          <LinearProgress variant="determinate" value={75} />
        </Box>

        <Box>
          <Typography className="sub-text">
            {" "}
            Select Test Drive Location
          </Typography>
        </Box>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={4}
          style={{ marginTop: "10px" }}
        >
          {locations?.map((location, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Box
                  className="location-box"
                  onClick={() => {
                    form.setFieldValue("location", location?.value);
                    setSelectedLocation(location?.value);
                    const obj = {
                      ...state?.testDriveDetails,
                      location: location?.value,
                      vehicleId: state?.userQlData?.selectedVehicleId,
                      // stage: "test-drive-confirmation",
                    };

                    const finalPayload = { ...state?.userQlData, ...obj };

                    let payload = {
                      location: location?.value,
                    };
                    sendAnalytics(
                      "v2_test_drive_select_location",
                      payload,
                      "en"
                    );
                    updateUserQlData(finalPayload);

                    updateTestDriveDetails(obj);
                  }}
                >
                  <Box
                    style={{
                      background:
                        form?.values?.location === location?.value
                          ? "#FEEBEF"
                          : "#ffffff",
                      padding: "10px",
                      border:
                        form?.values?.location === location?.value
                          ? "1px solid #E8345E"
                          : "none",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ marginRight: "15px" }}>
                      {location?.icon}
                    </Typography>
                    <Typography>{location?.label}</Typography>
                    {/* <LazyLoadImage
                      src={vehicleData?.homeImage}
                      style={{ width: "100%", minHeight: "140px" }}
                    /> */}
                  </Box>

                  {/* <Box>
                    <Typography className="vehicle-name">
                      {vehicleData?.name}
                    </Typography>
                  </Box> */}
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {selectedLocation === "Home" && (
          <Box style={{ marginTop: "30px" }}>
            <Box>
              <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                Location Details
              </Typography>
            </Box>

            <Box className="location-form-box">
              <form onSubmit={form.handleSubmit} className="form-schedule">
                <Grid container spacing={1}>
                  <Grid item xs={6} style={{ marginTop: "10px" }}>
                    <MemoizedSelectDropDown
                      className="input-dropdown"
                      sx={{
                        borderRadius: "8px",
                        background: "#ffffff !important",
                      }}
                      form={form}
                      id={"state"}
                      name="state"
                      required={true}
                      title={"Select State"}
                      value={form.values.state}
                      optionsData={states}
                      onChange={(e) => {
                        const query = `stateId=${e?.target?.value}`;
                        service(query)
                          .then((res) => {
                            setCities(res?.data?.payload?.cities);
                            setShowCity(true);
                          })
                          .catch((err) => {
                            showSnackbar(
                              "Oops, something went wrong. Please try again later.",
                              "error"
                            );
                          });
                        form.handleChange(e);
                      }}
                      error={!!checkError("state", form)}
                      helperText={form.errors.state}
                    />
                  </Grid>
                  {showCity && (
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      <MemoizedSelectDropDown
                        className="input-dropdown"
                        sx={{
                          borderRadius: "8px",
                          background: "#ffffff !important",
                        }}
                        form={form}
                        id={"city"}
                        name="city"
                        required={true}
                        title={"Select City"}
                        value={form.values.city}
                        optionsData={cities}
                        onChange={form.handleChange}
                        error={!!checkError("city", form)}
                        helperText={form.errors.city}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} style={{ marginTop: "10px" }}>
                    {" "}
                    <MemoizedNameField
                      style={{ borderRadius: "8px" }}
                      className="input-box"
                      name="address"
                      error={!!checkError("address", form)}
                      helperText={form.errors.address}
                      placeholder="Enter your address with Pincode"
                      value={form.values.address}
                      onChange={(e) => {
                        form.handleChange(e);

                        // form.setFieldValue("address", e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        )}

        <Box className="book-test-drive-button-box">
          <MemoizedButton
            content={"Book Test Drive"}
            loading={loading}
            disabled={form?.values?.location === "" ? true : false}
            sx={{
              alignItems: "center",
              width: "100%  !important",
              border: "0px",
              boxShadow: "none",
              borderRadius: "4px",
              background: "#F63B63 !important",
              color: "#FFFFFF  !important",
              "&:hover": {
                background: "#F63B63",
                color: "#FFFFFF",
              },
              "&:disabled": {
                background: "#DADADA !important",
              },
            }}
            handleClick={(e) => form.handleSubmit(e)}
          />
        </Box>
        {/* <Box
          className="back-button-box"
          onClick={() => {
            sendAnalytics("v2_test_drive_back", {}, "en");
            navigate.push("/test-drive/time");
          }}
        >
          <Typography className="back-button">
            <ArrowBackIosIcon sx={{ fontSize: "20px", width: "100%" }} /> Back
          </Typography>
        </Box> */}
      </Box>
    </CustomLocation>
  );
};

export default Location;
