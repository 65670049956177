import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useContext } from "react";
import { primary } from "../../../constants/theme";
import { newVehicles, vehicles } from "../../../constants/vehicleData";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";
import VehicleHorizontalCard from "../cards/vehicleHorizontalCard";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  margin: "10px",
  marginTop: "8%",

  ".head-text": {
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const data = [
  {
    name: "Milk Distributor",
    vehicleSold: "550",
    businessLines: "Sells Milk, Curd, Paneer",
    imgUrl: imagesWEBP.milkDistributors,
    value: "Milk Distributor",
    stage: "range",
  },
  {
    name: "Water Distributor",
    vehicleSold: "600",
    businessLines: "Sells Packaged Water",
    imgUrl: imagesWEBP.waterDistributors,
    value: "Water Distributor",
    stage: "range",
  },
  {
    name: "E-commerce",
    vehicleSold: "700",
    businessLines: "Online Retailer",
    imgUrl: imagesWEBP.ecommerce,
    value: "E-commerce",
    stage: "range",
  },
  {
    name: "Cylinder Distribution",
    vehicleSold: "450",
    businessLines: "Sells Gas Cylinders",
    imgUrl: imagesWEBP.cylinder,
    value: "Cylinder Distribution",
    stage: "range",
  },
  {
    name: "Vegetable and Fruits",
    vehicleSold: "800",
    businessLines: "Sells Fresh Produce",
    imgUrl: imagesWEBP.vegetableFruits,
    value: "Vegetable and Fruits",
    stage: "range",
  },
  {
    name: "Others",
    vehicleSold: "500",
    businessLines: "Sells Various Fish, FMCG Products",
    imgUrl: imagesWEBP.fishDistribution,
    value: "Others",
    stage: "range",
  },
];

const RecommendByChoice = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);

  const vehicleInfo = [...vehicles, ...newVehicles];

  const arrInfo = [];

  const recommendedVehicle = vehicleInfo?.filter((item) => {
    return vehicleInfo;
  });

  const onClickAction = (data) => {
    const payload = {
      ...state?.userQlData,
      stage: "business",
      brandName: data?.brand,
      vehicleSelected: data?.vehicle_name,
      selectedVehicleId: data?.vehicleId,
      isThreeWheelerReq: true,
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  return (
    <CustomCSSBox>
      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30%",
        }}
      >
        {" "}
        <LazyLoadImage
          src={imagesWEBP?.homePiaggio}
          style={{ width: "70%", alighItems: "center" }}
        />
      </Box> */}

      <Box sx={{ width: "100%" }}>
        <Typography className="head-text">
          <span style={{ color: primary?.main }}>Recommended vehicles</span> for
          your business 🚀
        </Typography>

        <Box>
          <TableContainer
            style={{
              height: "660px",
              background: "#F5F5F5",
              padding: "10px",
              borderRadius: "16px",
              width: "100%",
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableBody>
                {vehicleInfo.map((res, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 2 },
                      paddingTop: "10px",
                    }}
                  >
                    <VehicleHorizontalCard
                      headerInfo={"View"}
                      data={res}
                      onClickAction={onClickAction}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </CustomCSSBox>
  );
};

export default RecommendByChoice;
