import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MemoizedButton } from "../../../SDK/button";
import { primary } from "../../../constants/theme";
import { imagesWEBP } from "../../../constants/webpImages";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  //display: "flex",
  //justifyContent: "center",
  margin: "20px",
  // marginTop:"30%",
  // marginTop:"100px",

  ".head-text": {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const data = [
  {
    label: "2 wheeler",
    value: "2 wheeler",
    stage: "other-requirment",
    selection: false,
  },
  {
    label: "4 wheeler",
    value: "4 wheeler",
    stage: "other-requirment",
    selection: false,
  },
  {
    label: "Cargo Petrol/Diesel/CNG",
    value: "Cargo Petrol/Diesel/CNG",
    stage: "other-requirment",
    selection: false,
  },
  {
    label: "Passenger EV",
    value: "Passenger EV",
    stage: "other-requirment",
    selection: false,
  },
  {
    label: "Others",
    value: "Others",
    stage: "other-requirment",
    selection: false,
  },
];

const images = [
  { imgUrl: imagesWEBP?.postSaleCarousel1 },
  { imgUrl: imagesWEBP?.postSaleCarousel2 },
];

const WhySwitchtoEv = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);

  const onClickAction = (data) => {
    const payload = {
      ...state?.userQlData,
      mobile: "+91" + localStorage.getItem("phoneInfo") ?? "",
      otherBusiness: data?.value,
      stage: data?.stage,
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  const onClickAction3wEv = (data) => {
    const payload = {
      ...state?.userQlData,
      checkEvOptions: true,
      mobile: "+91" + localStorage.getItem("phoneInfo") ?? "",

      stage: "recommend-options",
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  return (
    <CustomCSSBox>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Typography className="head-text">
          Why you should Switch to EV ?
        </Typography>

        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // marginTop: "20%",
          }}
        >
          {" "}
          <LazyLoadImage
            src={imagesWEBP?.ice3Years}
            style={{ width: "80%", alignItems: "center" }}
          />
        </Box> */}
        <Box>
          <TableContainer
            style={{
              height: "480px",
              background: "#F5F5F5",
              padding: "10px",
              borderRadius: "16px",
              width: "100%",
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableBody>
                {images.map((res, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 2 },
                      paddingTop: "10px",
                    }}
                  >
                    <LazyLoadImage
                      src={res?.imgUrl}
                      style={{ width: "100%", alignItems: "center" }}
                      alt="image url"
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography
          className="head-text"
          onClick={onClickAction3wEv}
          style={{
            cursor: "pointer",
            color: primary?.secondary,
            fontWeight: "600",
            fontSize: "18px",
            marginTop: "16px",
          }}
        >
          <MemoizedButton
            content={"Explore 3-Wheeler EV Options"}
            onClick={onClickAction3wEv}
          />
        </Typography>
      </Box>
    </CustomCSSBox>
  );
};

export default WhySwitchtoEv;
