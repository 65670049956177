function SvgIcon2({ className }) {
  return (
    <div className={`${className} ${className}`}>
      <svg
        fill="none"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <defs>
          <clipPath id="clip3_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="11.79"
              width="11.79"
              fill="white"
              transform="translate(0.10479999999999734, 0.1054499999999976)"
            />
          </clipPath>
          <clipPath id="clip4_59_3437" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="11.79"
              width="11.79"
              fill="white"
              transform="translate(0.10479999999999734, 0.1054499999999976)"
            />
          </clipPath>
        </defs>
        <g
          id="iconTransmission.svg"
          clipPath="url(#clip3_59_3437)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="iconTransmission.svg fill" clipPath="url(#clip4_59_3437)">
            <g id="iconTransmission.svg_2">
              <g id="Group 6555">
                <g id="Group 6554">
                  <g id="Group 6553">
                    <g id="Group 6552">
                      <g id="Group 6551">
                        <path
                          id="Path 5557"
                          d="M2.5467,2.85145v6.35"
                          stroke="#59646F"
                          strokeLinecap="round"
                          strokeWidth="0.999915"
                        />
                        <path
                          id="Path 5558"
                          d="M6.0113,2.85115v6.35"
                          stroke="#59646F"
                          strokeLinecap="round"
                          strokeWidth="0.999915"
                        />
                        <path
                          id="Path 5559"
                          d="M9.4749,2.27315v3.465"
                          stroke="#59646F"
                          strokeLinecap="round"
                          strokeWidth="0.999915"
                        />
                        <path
                          id="Path 5560"
                          d="M2.5435,5.73785h6.924"
                          stroke="#59646F"
                          strokeLinecap="round"
                          strokeWidth="0.999915"
                        />
                        <path
                          id="Ellipse 213"
                          d="M2.5278,3.01635c0.6478,0 1.173,-0.5251 1.173,-1.173c0,-0.6478 -0.5252,-1.173 -1.173,-1.173c-0.6478,0 -1.173,0.5252 -1.173,1.173c0,0.6479 0.5252,1.173 1.173,1.173z"
                          fill="#58636E"
                        />
                        <path
                          id="Ellipse 214"
                          d="M5.9924,3.01635c0.6478,0 1.173,-0.5251 1.173,-1.173c0,-0.6478 -0.5252,-1.173 -1.173,-1.173c-0.6478,0 -1.173,0.5252 -1.173,1.173c0,0.6479 0.5252,1.173 1.173,1.173z"
                          fill="#58636E"
                        />
                        <path
                          id="Ellipse 215"
                          d="M9.4722,3.01635c0.6478,0 1.173,-0.5251 1.173,-1.173c0,-0.6478 -0.5252,-1.173 -1.173,-1.173c-0.6479,0 -1.173,0.5252 -1.173,1.173c0,0.6479 0.5251,1.173 1.173,1.173z"
                          fill="#58636E"
                        />
                        <path
                          id="Ellipse 216"
                          d="M5.9924,11.32965c0.6478,0 1.173,-0.5252 1.173,-1.173c0,-0.6479 -0.5252,-1.173 -1.173,-1.173c-0.6478,0 -1.173,0.5251 -1.173,1.173c0,0.6478 0.5252,1.173 1.173,1.173z"
                          fill="#58636E"
                        />
                        <path
                          id="Ellipse 217"
                          d="M2.5447,11.32965c0.6478,0 1.173,-0.5252 1.173,-1.173c0,-0.6479 -0.5252,-1.173 -1.173,-1.173c-0.6479,0 -1.173,0.5251 -1.173,1.173c0,0.6478 0.5251,1.173 1.173,1.173z"
                          fill="#58636E"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon2;
