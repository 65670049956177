import { Box, Grid, Typography, styled } from "@mui/material";
import { useContext } from "react";
import { MemoizedButton } from "../../../SDK/button";
import { primary } from "../../../constants/theme";
import { Context } from "../../../context/context";
import LeadInfoService from "../../../services/leadInfoService";
import BrandTapCard from "../cards/brandTapCard";

const CustomCSSBox = styled(Box)(({ theme }) => ({
  padding: "20px",
  paddingTop: "8%",
  background: primary?.lightBlue,
  height: "100vh",

  ".head-text": {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {},
}));

const data = [
  {
    id: 1,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahidra_logo.webp `,
    BrandName: "Mahindra",
    alt: "Mahindra logo",
    vehicleSold: "700",
    stage: "selected-brand",
    value: "Mahindra",
    businessLines: "Best Seller",
  },
  {
    id: 2,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/piaggio_logo.avif`,
    BrandName: "Piaggio",
    alt: "Piaggio logo",
    vehicleSold: "470",
    stage: "selected-brand",
    value: "Piaggio",
    businessLines: "Best Seller",
  },
  {
    id: 3,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/png/Frame.png`,
    BrandName: "Etrio",
    alt: "Etrio logo",
    vehicleSold: "240",
    stage: "selected-brand",
    value: "Etrio",
    businessLines: "Best Seller",
  },
  {
    id: 4,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}vehicleBrandLogos/avif/Group-1000004749.avif`,
    BrandName: "OSM",
    alt: "OSM logo",
    vehicleSold: "135",
    stage: "selected-brand",
    value: "OSM",
    businessLines: "Best Seller",
  },
  {
    id: 5,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/euler_logo.webp`,
    BrandName: "Euler",
    alt: "EUler logo",
    vehicleSold: "490",
    stage: "selected-brand",
    value: "Euler",
    businessLines: "Best Seller",
  },
  {
    id: 6,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreenlogo.webp`,
    BrandName: "Altigreen",
    alt: "Altigreen logo",
    vehicleSold: "340",
    stage: "selected-brand",
    value: "Altigreen",
    businessLines: "Best Seller",
  },
  {
    id: 7,
    vehicle_logo: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandLogo/greaveslogo.webp`,
    BrandName: "Greaves",
    alt: "Greaves logo",
    vehicleSold: "120",
    stage: "selected-brand",
    value: "Greaves",
    businessLines: "Best Seller",
  },
];

const BrandAvailable = () => {
  const { updateUserQlData } = useContext(Context);
  const state = useContext(Context);

  const onClickAction = (data) => {
    const payload = {
      ...state?.userQlData,
      stage: data?.stage,
      brandName: data?.value,
    };

    updateUserQlData(payload);
    LeadInfoService(payload);
  };

  const onClickActionOtherBrand = () => {
    const payload = {
      ...state?.userQlData,
      stage: "default",
    };

    updateUserQlData(payload);
  };

  return (
    <CustomCSSBox>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Typography className="head-text">
          What is your purpose or business for buying this vehicle?
        </Typography>
        <Grid container spacing={2} sx={{ padding: "20px" }}>
          {data?.map((res, index) => {
            return (
              <Grid
                key={index}
                item
                sx={{ width: "100%", height: "100%" }}
                xs={6}
              >
                <BrandTapCard
                  data={res}
                  onClickAction={() => onClickAction(res)}
                />
              </Grid>
            );
          })}
        </Grid>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            className="head-text"
            // onClick={onClickAction3wEv}
            style={{
              cursor: "pointer",
              color: primary?.secondary,
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            <MemoizedButton
              content={"Looking for other brands?"}
              sx={{ background: primary?.secondary }}
              onClick={onClickActionOtherBrand}
            />
          </Typography>
        </Box>
      </Box>
    </CustomCSSBox>
  );
};

export default BrandAvailable;
