import axios from "axios";
import { sendAnalytics } from "../utils/analyticsCommon";

export const headers = {
  "Content-Type": "application/json",
};

const LeadInfoService = (payload) => {
  headers.auth_token = process.env.NEXT_PUBLIC_TOKEN;
  let paraLocal = JSON.parse(localStorage.getItem("urlParams"));

  const userDetails = {
    leadUuid: localStorage?.getItem("uuid") ?? "",
    uniqueUserId: localStorage?.getItem("user_id").replace(/^"|"$/g, ""),
    expVariant: "",
    stage: payload?.stage ?? "",
    subStage: "",
    testDriveOpted: payload?.testDriveStage ? true : false,
    pinCode: payload?.address ?? "",
    testDriveDate: payload?.date ?? "",
    testDriveTime: payload?.timeSlot ?? "",
    brand: payload?.brandName ?? "",
    model: payload?.vehicleSelected ?? "",
    variant: "",
    vehicleUsageLoad: payload?.loadCapacity ?? "",
    vehicleUsageDistance: payload?.range ?? "",
    purchaseUrgency: payload?.purchaseDuration ?? "",
    chargingRequirement: "",
    useCaseDetails: "",
    budget: payload?.priceRange ?? "",
    leadUseCase: payload?.business ?? "",
    createdBy: "",
    metaDataList: [
      {
        key: "UTM_SOURCE",
        value: paraLocal?.utm_source ?? "Organic",
      },
      {
        key: "UTM_CAMPAIGN",
        value: paraLocal?.utm_campaign ?? "999",
      },
      {
        key: "UTM_MEDIUM",
        value: paraLocal?.utm_medium ?? "Organic",
      },
      {
        key: "UTM_CONTENT",
        value: paraLocal?.utm_content ?? "Organic",
      },
      {
        key: "OFFER_SUB_SOURCE",
        value: payload?.subSource ?? "",
      },
      {
        key: "SOURCE_NAME",
        value: "turno-offer",
      },
      {
        key: "UTM_TERM",
        value: paraLocal?.utm_term ?? "Unknown",
      },
    ],
  };

  //console.log(userDetails, "userDetails");

  sendAnalytics(payload?.stage, { ...userDetails }, "en");

  return axios.post("/leads/website/requirement", userDetails);
};

export default LeadInfoService;
