import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useContext, useState } from "react";

import { Context } from "../../context/context";
import LeadInfoService from "../../services/leadInfoService";
import { sendAnalytics } from "../../utils/analyticsCommon";

const useTestDriveScheduler = () => {
  const state = useContext(Context);

  const { showSnackbar, changeState, hideReviewStoryDrawer } =
    useContext(Context);
  const [loading, setLoading] = useState(false);
  const navigate = useRouter();

  const form = useFormik({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      vehicle_id: state?.testDriveDetails?.vehicleId,
      date: state?.testDriveDetails?.date,
      timeSlot: state?.testDriveDetails?.timeSlot,
      location: state?.testDriveDetails?.location,
      state: state?.testDriveDetails?.state,
      city: state?.testDriveDetails?.city,
      address: state?.testDriveDetails?.address,
    },

    validate: (values) => {
      const errors = {};

      if (values?.location === "Home") {
        if (values?.state !== "none" && values?.state !== null) {
          sendAnalytics(
            "v2_test_drive_select_state",
            { state: values?.state },
            "en"
          );
        }
        if (values?.city !== "none" && values?.city !== null) {
          sendAnalytics(
            "v2_test_drive_select_city",
            { city: values?.city },
            "en"
          );
        }
      }
      //   if (!values?.mobile?.toString()) {
      //     errors.mobile = "Mobile Number is Mandatory";
      //   }
      //   if (values?.mobile?.toString()?.length !== 10) {
      //     errors.mobile = "Enter a valid 10 digit Mobile Number";
      //   }
      //   if (values?.mobile && values?.mobile[0] < 5) {
      //     errors.mobile = "Enter Valid Mobile Number";
      //   }

      return errors;
    },

    onSubmit: (values) => {
      setLoading(true);
      if (values?.location === "Home") {
        sendAnalytics(
          "v2_test_drive_address",
          { address: values?.address },
          "en"
        );
      }
      let payload = {
        vehicleId: values?.vehicle_id,
        timeSlot: values?.timeSlot,
        date: values?.date,
        address: values?.address,
        state: values?.state,
        city: values?.city,
        location: values?.location,
      };

      sendAnalytics("v2_test_drive_confirm_submit", payload, "en");
      LeadInfoService(state?.userQlData);
      navigate.push("/#test-drive");
      hideReviewStoryDrawer();
    },
  });

  return {
    form,
    loading,
  };
};

export default useTestDriveScheduler;
